@import "react-calendar/dist/Calendar.css";
@import "react-date-picker/dist/DatePicker.css";

@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --theme-clr: #111111;

  --bs-white: #fff;
  --bs-body-font-size: 1rem;
  --bs-body-line-height: 21px;
  --bs-border-color: #e4e4e4;
  --bs-border-radius: 10px;
  --bs-primary: #111111;
  --bs-secondary: #6b6b6b;
  --bs-light: #f1f1f1;
  --bs-warning: #ff3131;
  --sm: 14px;
  --sm-lnh: 20px;
  --mdlg: 20px;
  --mdlg-lnh: 30px;
  --md: 15px;
  --md-lnh: 22px;
  --lg: 24px;
  --lg-lnh: 36px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: #000000;
}

/* font size start */
.fs-mdlg {
  font-size: var(--mdlg);
  line-height: var(--mdlg-lnh);
}
.fs-md {
  font-size: var(--md);
  line-height: var(--md-lnh);
}
.fs-lg {
  font-size: var(--lg);
  line-height: var(--lg-lnh);
}
.fs-sm {
  font-size: var(--sm);
  line-height: var(--sm-lnh);
}
/* font size end */
/*font weight start*/
.font-regular,
.fw-400 {
  font-weight: 400 !important;
}
h1,
.font-bold,
.fw-700,
.fw-bold {
  font-weight: 700;
}
.font-semibold,
.fw-600 {
  font-weight: 600;
}
.fw-500,
.font-medium {
  font-weight: 500;
}
/*font weight end*/

/* color css start */
.text-secondary {
  color: var(--bs-secondary);
}
.text-white {
  color: var(--bs-white);
}
/* color css end */

html,
body {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #000000;
  background: var(--bs-light);
  font-family: "Inter", sans-serif;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
  overflow-x: hidden;
}

main {
  background-color: var(--bs-light);
  min-height: 100vh;
}

.fix-height {
  max-height: calc(calc(var(--vh, 1vh) * 100) - 70px);
}

main,
footer {
  margin: 0 auto;
  /*  max-width: 1440px;*/
}

svg {
  display: inline;
  color: inherit;
}

svg.ic {
  fill: currentColor;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: none;
  box-shadow: none;
  color: inherit;
}

/*bootstrap grid css*/

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1250px;
}

.container,
.container-fluid {
  --bs-gutter-x: 22.5px;
}

.row {
  --bs-gutter-x: 45px;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

/*bootstrap grid css end*/

/* spacing start */
.mb-50 {
  margin-bottom: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.me-6 {
  margin-right: 6px;
}
.me-20 {
  margin-right: 20px;
}
.px-lg-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.py-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}
/* spacing end */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* button css start */

.btn,
.btn:hover,
.btn:active,
.btn:focus {
  padding: 5px 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  box-shadow: none;
  background-color: #111111;
  border-radius: 0;
  color: #ffffff;
  height: 36px;
  transition: 0.3s ease-out;
  white-space: nowrap;
}

.btn-red,
.btn-red:hover,
.btn-red:active,
.btn-red:focus {
  border: 1px solid #ff3131;
  color: #ff3131;
  background-color: #ffffff;
}

.btn-light,
.btn-light:hover,
.btn-light:active,
.btn-light:focus {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #ececec;
  background-color: #f8f8f8;
  color: #6b6b6b;
  padding: 5px 10px;
}

/* button css end */

/* form css start */
hr {
  border: 1px solid #ebebeb;
  opacity: 1;
}
.form-label {
  margin-bottom: 5px;
}
.form-control::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-secondary);
}

.form-control::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-secondary);
}

.form-control:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-secondary);
}

.form-control:-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-secondary);
}

.form-control,
.form-control:hover,
.form-control:focus,
.form-control:active {
  color: #111111;
  padding: 8px 10px;
  background: var(--bs-white);
  border: 1px solid var(--bs-border-color);
  height: 36px;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
}

.form-group {
  margin-bottom: 20px;
}

.form-select,
.form-select:active,
.form-select:hover,
.form-select:focus {
  box-shadow: none;
  color: #6b6b6b;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #ececec;
  border-radius: 0px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiM2QjZCNkIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 24px;
  padding-right: 32px;
  height: 32px;
}

.form-search,
.form-search:hover,
.form-search:active,
.form-search:focus {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxIDIxTDE1IDE1TTMgMTBDMyAxMC45MTkzIDMuMTgxMDYgMTEuODI5NSAzLjUzMjg0IDEyLjY3ODhDMy44ODQ2MyAxMy41MjgxIDQuNDAwMjQgMTQuMjk5NyA1LjA1MDI1IDE0Ljk0OTdDNS43MDAyNiAxNS41OTk4IDYuNDcxOTQgMTYuMTE1NCA3LjMyMTIyIDE2LjQ2NzJDOC4xNzA1IDE2LjgxODkgOS4wODA3NSAxNyAxMCAxN0MxMC45MTkzIDE3IDExLjgyOTUgMTYuODE4OSAxMi42Nzg4IDE2LjQ2NzJDMTMuNTI4MSAxNi4xMTU0IDE0LjI5OTcgMTUuNTk5OCAxNC45NDk3IDE0Ljk0OTdDMTUuNTk5OCAxNC4yOTk3IDE2LjExNTQgMTMuNTI4MSAxNi40NjcyIDEyLjY3ODhDMTYuODE4OSAxMS44Mjk1IDE3IDEwLjkxOTMgMTcgMTBDMTcgOS4wODA3NSAxNi44MTg5IDguMTcwNSAxNi40NjcyIDcuMzIxMjJDMTYuMTE1NCA2LjQ3MTk0IDE1LjU5OTggNS43MDAyNiAxNC45NDk3IDUuMDUwMjVDMTQuMjk5NyA0LjQwMDI0IDEzLjUyODEgMy44ODQ2MyAxMi42Nzg4IDMuNTMyODRDMTEuODI5NSAzLjE4MTA2IDEwLjkxOTMgMyAxMCAzQzkuMDgwNzUgMyA4LjE3MDUgMy4xODEwNiA3LjMyMTIyIDMuNTMyODRDNi40NzE5NCAzLjg4NDYzIDUuNzAwMjYgNC40MDAyNCA1LjA1MDI1IDUuMDUwMjVDNC40MDAyNCA1LjcwMDI2IDMuODg0NjMgNi40NzE5NCAzLjUzMjg0IDcuMzIxMjJDMy4xODEwNiA4LjE3MDUgMyA5LjA4MDc1IDMgMTBaIiBzdHJva2U9IiM3QjdCN0IiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: left 7px center;
  background-size: 24px 24px;
  padding-left: 40px;
  height: 36px;
  width: 300px;
  border: 1px solid #f1f1f1;
  border-radius: 0px;
}

.form-detail-search,
.form-detail-search:hover,
.form-detail-search:active,
.form-detail-search:focus {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxIDIxTDE1IDE1TTMgMTBDMyAxMC45MTkzIDMuMTgxMDYgMTEuODI5NSAzLjUzMjg0IDEyLjY3ODhDMy44ODQ2MyAxMy41MjgxIDQuNDAwMjQgMTQuMjk5NyA1LjA1MDI1IDE0Ljk0OTdDNS43MDAyNiAxNS41OTk4IDYuNDcxOTQgMTYuMTE1NCA3LjMyMTIyIDE2LjQ2NzJDOC4xNzA1IDE2LjgxODkgOS4wODA3NSAxNyAxMCAxN0MxMC45MTkzIDE3IDExLjgyOTUgMTYuODE4OSAxMi42Nzg4IDE2LjQ2NzJDMTMuNTI4MSAxNi4xMTU0IDE0LjI5OTcgMTUuNTk5OCAxNC45NDk3IDE0Ljk0OTdDMTUuNTk5OCAxNC4yOTk3IDE2LjExNTQgMTMuNTI4MSAxNi40NjcyIDEyLjY3ODhDMTYuODE4OSAxMS44Mjk1IDE3IDEwLjkxOTMgMTcgMTBDMTcgOS4wODA3NSAxNi44MTg5IDguMTcwNSAxNi40NjcyIDcuMzIxMjJDMTYuMTE1NCA2LjQ3MTk0IDE1LjU5OTggNS43MDAyNiAxNC45NDk3IDUuMDUwMjVDMTQuMjk5NyA0LjQwMDI0IDEzLjUyODEgMy44ODQ2MyAxMi42Nzg4IDMuNTMyODRDMTEuODI5NSAzLjE4MTA2IDEwLjkxOTMgMyAxMCAzQzkuMDgwNzUgMyA4LjE3MDUgMy4xODEwNiA3LjMyMTIyIDMuNTMyODRDNi40NzE5NCAzLjg4NDYzIDUuNzAwMjYgNC40MDAyNCA1LjA1MDI1IDUuMDUwMjVDNC40MDAyNCA1LjcwMDI2IDMuODg0NjMgNi40NzE5NCAzLjUzMjg0IDcuMzIxMjJDMy4xODEwNiA4LjE3MDUgMyA5LjA4MDc1IDMgMTBaIiBzdHJva2U9IiM3QjdCN0IiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: left 5px center;
  background-position-y: 2px;
  background-size: 20px 20px;
  padding-left: 30px;
  height: 25px;
  width: 200px;
  border: 1px solid #f1f1f1;
  border-radius: 0px;
  background-color: "#f8f8f8";
  color: "#6b6b6b";
}

.textarea-height {
  height: 108px;
  min-height: 108px !important;
}
.form-label {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #6b6b6b;
}

/* form css end */

/* login css start */
.login {
  height: 100%;
  width: 100%;
  background-color: var(--bs-light);
}

.logfrm {
  min-height: 100vh;
}

.login-column {
  background: var(--bs-white);
  border-radius: 6px;
  max-width: 506px;
  padding: 40px 30px;
}

.pw-hide {
  position: relative;
}

.pw-hide .hide-password {
  position: absolute;
  right: 25px;
  top: 0;
  height: 36px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxIDlDMTguNiAxMS42NjcgMTUuNiAxMyAxMiAxM0M4LjQgMTMgNS40IDExLjY2NyAzIDlNMyAxNUw1LjUgMTEuMk0yMSAxNC45NzZMMTguNTA4IDExLjJNOSAxN0w5LjUgMTNNMTUgMTdMMTQuNSAxMyIgc3Ryb2tlPSIjNkI2QjZCIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
  background-position: right 10px center;
  background-repeat: no-repeat;
  padding-right: 40px;
  cursor: pointer;
}

.pw-hide .show-password {
  position: absolute;
  right: 25px;
  top: 0;
  height: 36px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDEyQzEwIDEyLjUzMDQgMTAuMjEwNyAxMy4wMzkxIDEwLjU4NTggMTMuNDE0MkMxMC45NjA5IDEzLjc4OTMgMTEuNDY5NiAxNCAxMiAxNEMxMi41MzA0IDE0IDEzLjAzOTEgMTMuNzg5MyAxMy40MTQyIDEzLjQxNDJDMTMuNzg5MyAxMy4wMzkxIDE0IDEyLjUzMDQgMTQgMTJDMTQgMTEuNDY5NiAxMy43ODkzIDEwLjk2MDkgMTMuNDE0MiAxMC41ODU4QzEzLjAzOTEgMTAuMjEwNyAxMi41MzA0IDEwIDEyIDEwQzExLjQ2OTYgMTAgMTAuOTYwOSAxMC4yMTA3IDEwLjU4NTggMTAuNTg1OEMxMC4yMTA3IDEwLjk2MDkgMTAgMTEuNDY5NiAxMCAxMloiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTIxIDEyQzE4LjYgMTYgMTUuNiAxOCAxMiAxOEM4LjQgMTggNS40IDE2IDMgMTJDNS40IDggOC40IDYgMTIgNkMxNS42IDYgMTguNiA4IDIxIDEyWiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
  background-position: right 10px center;
  background-repeat: no-repeat;
  padding-right: 40px;
  cursor: pointer;
}

/* login css end */

.dropdown-toggle::after {
  display: none;
}

.sidebar {
  position: fixed;
  left: 0;
  height: calc(100dvh);
  top: 0;
  z-index: 3;
  bottom: 0;
  border-radius: 0;
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  border-right: 1px solid var(--bs-border-color);
  width: 240px;
  min-width: 240px;
  background-color: #000000;
}

.brand-logo {
  text-align: center;
  padding: 5px;
}
main {
  padding-top: calc(64px + 24px);
  padding-left: calc(240px + 20px);
  padding-right: 20px;
}
.logout-bottom-position {position: absolute;
    bottom: 4em;}

/* topbar css */
.topbar {
  height: 64px;
  z-index: 10;
}
.user-drop .btn {
  background-color: transparent;
  border: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* navbar */
.navbar-toggler {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: 16px;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  position: relative;
}

.navbar-toggler,
.navbar-toggler span.icon-bar {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  width: 18px;
}

.navbar-toggler span.icon-bar {
  display: block;
  height: 2px;
  margin-left: auto;
  opacity: 0.8;
  text-align: right;
  background-color: #111;
}

.navbar-toggler span.icon-bar.bottom-bar {
  margin-bottom: 0;
}

.navbar-toggler.cross .icon-bar {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.navbar-toggler.cross .top-bar {
  position: absolute;
  top: 0.5rem;
  transform: rotate(45deg);
}

.navbar-toggler.cross .middle-bar {
  opacity: 0;
}

.navbar-toggler.cross .bottom-bar {
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  width: 18px !important;
}

.menu {
  position: relative;
  height: calc(100% - 30px);
}

.menu ul li .active {
  background-color: #242323;
}

.menu ul li a {
  display: flex;
  padding: 13px 20px;
  align-items: center;
  justify-content: space-between;
  color: var(--bs-white);
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
}

.overlay {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #262f37;
  opacity: 0.5;
  z-index: 1;
  display: none;
}

.overlay.show {
  display: block;
}

.cancel-button-background {
  background-color: #f6f6f6e7 !important;
  color: #000000 !important;
  border: 1px solid #f6f6f6e7 !important;
}

.cancel-button-background :hover {
  color: #000000 !important;
}

/* dashboard start */
.dashboard-clock {
  background-color: #ffffff;
  height: calc(calc(var(--vh, 1vh) * 100) - 110px);
  margin-left: 10px;
  margin-right: 10px;
}
.dashboard-clockin {
  height: calc(calc(var(--vh, 1vh) * 100) - 110px);
}
.total-time-tracking {
  border-bottom: 1px solid #e4e4e4;
  background-color: #e1e1e1;
  padding: 8px 30px;
      width: 100%;
    overflow-x: auto;
        white-space: nowrap;
}

.time-tracking {
  padding: 4px 20px;
  border-bottom: 1px solid #e4e4e4;
}
.time-tracking .col {
  padding: 10px;
}
.border-end-time {
  border-right: 1px solid #ececec;
}
.time-tracking button {
  border: 0;
  background-color: transparent;
}
.time-tracking-count {
  background-color: #efefef;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: inline-block;
}
.max-w-15 {
  max-width: 15%;
}
.max-w-9 {
  max-width: 9%;
}
.max-w-6 {
  max-width: 6%;
}
/* dashboard end */
/* project start */
.table tr th {
  padding: 9px 30px;
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  background: #e1e1e1;
  border-bottom: 1px solid #e4e4e4;
  white-space: nowrap;
}
.table tr td {
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  padding: 14px 30px;
  border-bottom: 1px solid #e4e4e4;
  /* white-space: nowrap; */
  max-width: 160px !important;
}
.vendor-table .table tr th {
  background: #ffffff;
  color: #6b6b6b;
}
/* project end */
/* profile start */
.profile-spacing {
  padding: 30px 40px;
}

.image-container {
  position: relative; /* Add position relative to contain the absolute positioned children */
  max-height: fit-content;
  max-width: fit-content;
}

.baneeroverlay,
.profile-drag {
  display: none; /* Initially hide */
}

.image-container:hover .baneeroverlay,
.image-container:hover .profile-drag {
  display: block; /* Show on hover */
}

.baneeroverlay {
  background-color: #00000080;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 111px;
  height: 111px;
  border-radius: 50%;
}

.profile-drag {
  position: absolute;
  top: 31px;
  left: 18px;
  text-align: center;
}

.image-container img {
  border-radius: 50%; /* Add this to make sure image has rounded corners */
}

.upload-img input[type="file"] {
  display: none;
}

.upload-img .custom-file-upload {
  display: inline-block;
  cursor: pointer;
  margin-left: -7px;
}

/* profile end */
@media only screen and (max-width: 991px) {
  .sidebar {
    padding-top: 64px;
  }

  main {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sidebar.navHide {
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transform: translateX(calc(-100% - 5px));
    -webkit-transform: translateX(calc(-100% - 5px));
    -moz-transform: translateX(calc(-100% - 5px));
  }

  .menu ul li {
    display: block;
  }

  .menu ul li a .nav-text {
    display: block;
    position: static;
    background: transparent;
  }

  .nav-text::before {
    content: none;
  }
}

@media (min-width: 992px) {
  .topbar {
    margin-left: 240px;
  }
}

@media (max-width: 767.98px) {
  .total-time-tracking div { padding-right: 10px;}
  .total-time-tracking {    padding: 8px 10px;}
  .time-tracking {
    padding: 4px 10px;}
  .px-lg-30 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .max-w-15 {
    max-width: 49%;
  }
  .max-w-9 {
    max-width: 27%;
  }
  .max-w-6 {
    max-width: 13%;
  }
  .time-tracking .col {
    text-wrap: nowrap;
  }
  .time-tracking {
    width: 100%;
    overflow-x: auto;
  }
}

.summary-react-date-picker {
  padding: 5px !important;
  padding-left: 35px !important;
  border: 1px solid #cccccc !important;
  border-radius: 3.5px;
  width: 180px !important;
}

.custom-react-date-picker{
  padding: 10px !important;
  margin-top: 7px !important;
  border-radius: 3.5px;
  width: 100% !important;
  border: none;
  font-size: 16px;
  display: block!important;
}

.react-datepicker-wrapper {
  display: block !important;
  
}

.summary-react-date-picker .react-date-picker__wrapper {
  border: 1px solid grey;
  padding: 0.4375rem 0.625rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  padding-right: 40px;
  padding: 0;
  border: 0;
  width: 100% !important;
  margin-left: 10px;
}

.react-date-picker__wrapper {
  border: none;
}

.react-datepicker .react-date-picker__inputGroup {
  padding: 0;

  border: 0;
}

.react-date-picker__inputGroup__input:focus {
  padding: 0;
  
  border: 0;
  outline: none;
}

.react-date-picker__calendar {
  max-width: 270px !important;
  z-index: 10001 !important;
  width: 270px !important;
  padding: 0;
  border: 0;
}

.react-date-picker__calendar .react-calendar {
  padding: 0;
  margin: 0;
  border: 0;
  padding: 0;
  border: 0;
  width: auto;
  border: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; */
  z-index: 10001 !important;
  border-radius: 0.375rem;
}

.react-date-picker__calendar
  .react-calendar
  .react-calendar__month-view__days
  button {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0;
  border: 0;
  width: 33px;
  height: 33px;
  min-width: 32px;
}

.react-calendar__navigation button {
  min-width: 32px !important;
}

.react-calendar__year-view .react-calendar__tile {
  padding: 1.5em 0.5em !important;
}

.pro-banner {
  position: relative !important;
  background-color: var(--bs-light) !important;
}
.pro-banner + .form-file {
  position: static !important;
}

.pro-banner + .form-file input {
  bottom: 27px;
}

.three-dots {
  width: 100px;
  text-overflow: ellipsis !important;
  overflow: hidden;
  line-break: unset;
  white-space: nowrap;
}

.user-profile-menu {
  position: absolute;
  top: 60px; /* Adjust this value based on your layout */
  right: 10px; /* Adjust this value based on your layout */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.user-profile-menu button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

.user-profile-menu button:hover {
  background-color: #f0f0f0;
}

.truncate-text {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  margin: 0;
}

.dashboard-table .table tr td {    padding: 14px 10px;}

.dashboard-table .table tr th {
    padding: 14px 10px; font-weight: 600;}

.employee-width {width: 250px;}

/* iPadPRO landscape style here */

@media only screen and (min-device-width: 1000px) and (max-device-width: 1100px) {
.max-w-9 {
    max-width: 15%;
}
.mb-res-20 {margin-bottom: 20px;}
}
/* iPad landscape style here */
@media only screen and (min-device-width: 768px) and (max-device-width: 992px) {
.max-w-9 {
    max-width: 15%;
}
.mb-res-20 {margin-bottom: 20px;}
}
@media (max-width: 767.98px) { 
  .truncate-text {    overflow: initial; margin-right: 5px;}
  .me-20 {margin-right: 8px;}
  .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
    padding: 5px 2px;}
  .btn-sm, .btn-sm:hover, .btn-sm:active, .btn-sm:focus {
    padding: 5px 2px;}
    .profile-spacing {
    padding: 20px 10px;
}
.mb-res-20 {margin-bottom: 20px;}
.employee-width {width: auto;}
}